import { type InternalLink, MarketCountryCode } from '@backmarket/http-api'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconSim } from '@ds/icons/IconSim'
import { IconSwap } from '@ds/icons/IconSwap'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/constants'

import { MODAL_NAMES } from '../../../constants'
import translations from '../CustomizationFunnel.translations'
import { type Prefixes as PickerPrefixes } from '../components/LargePicker/LargePicker.types'

import { getMobilePlanStep } from './mobilePlan'
import type { Option, Step } from './types'
import { mapOption } from './utils'

const mapSimSubLabel = (option: Option): Option => {
  const key = `sim_${option.trackingValue}`

  return {
    ...option,
    subLabel: translations[key] ? translations[key] : '',
  }
}

function getPremiumIcon(
  option: GetPickersResponse['pickers'][number]['items'][number],
): PickerPrefixes {
  if (!option.available) {
    return 'IconDiamond'
  }

  if (option.selected) {
    return 'AnimatedDiamondFilled'
  }

  return 'AnimatedDiamondOutlined'
}

/**
 * This function is here to map the response of existing endpoints (pickers, best_offers) to
 * something easily usable by the Customization Funnel.
 *
 * We chose to reuse existing endpoints over a new dedicated one to gain some time developing the
 * MVP that will be A/B tested on smartphones only. This is not meant to be scaled to any other category
 * without a new endpoint to be created.
 */
export const createCustomizationFunnelData = (
  i18n: ReturnType<typeof useI18n>,
  pickers: GetPickersResponse['pickers'],
  product: GetProductResponse,
  route: InternalLink,
  swapStatus: string,
  swapOffer?: Estimation,
  ppBouyguesPositionExperiment = 'noVariation',
  ppBouyguesOffersFilter = 'noVariation',
  ppBatteryCopyExperiment = 'noVariation',
  ppHideBatteryStepExperiment = 'noVariation',
  countryCode: MarketCountryCode = MarketCountryCode.US,
): Step[] => {
  const pickersGrade = pickers.find(({ id }) => id === 'grades')
  const pickersStorage = pickers.find(({ id }) => id === 'storage')
  const pickersColor = pickers.find(({ id }) => id === 'color')
  const pickersSim = pickers.find(({ id }) => id === 'dual_sim')
  const pickersBattery = pickers.find(({ id }) => id === 'battery')
  const pickersMobilePlan = pickers.find(({ id }) => id === 'mobile_plan')

  const isNewBatteryAvailable = pickersBattery?.items?.[1]?.available ?? false
  const isPremiumGradeSelected =
    pickersGrade?.items?.find(({ selected }) => !!selected)?.grade?.value === 9

  const steps: Array<Step | null> = pickers
    .map((group) => {
      switch (group.id) {
        case 'grades': {
          const hasGoodDeal = !!pickersGrade?.items.find(
            (item) => item.goodDeal,
          )

          return {
            id: 'grade',
            title: translations.titleStepGrade,
            titleEmphasis: translations.titleEmphasisStepGrade,
            trackingId: pickersGrade?.trackingId,
            columns: 2,
            options:
              pickersGrade?.items.map((option) => ({
                ...mapOption(
                  option,
                  // We should use option.grade.value but it's not defined when there is not stock for this grade.
                  // eslint-disable-next-line no-nested-ternary
                  option.trackingValue === '9'
                    ? getPremiumIcon(option)
                    : 'circle',
                ),
                tag:
                  !hasGoodDeal && option.grade?.value === 11 && option.available
                    ? i18n(translations.bestValue)
                    : undefined,
              })) ?? [],
          }
        }
        case 'battery': {
          let title
          let titleEmphasis
          let guidanceLabel = translations.helpCardBattery
          let newBatteryNoLabel = translations.newBatteryOptionNo
          let newBatteryLabel = translations.newBatteryOptionYes
          let newBatteryDescription: I18nDefinition | string =
            translations.newBatteryOptionYesDescription
          let modalName = MODAL_NAMES.NEW_BATTERY
          let prefix: PickerPrefixes = 'circle'

          if (isPremiumGradeSelected) {
            title = translations.titleStepBatteryPremium
            titleEmphasis = i18n(translations.titleEmphasisStepBatteryPremium, {
              brand: product.trackingDetails.brand,
            })
            guidanceLabel = translations.helpCardBatteryPremium
            newBatteryLabel = translations.newBatteryOptionYesPremium
            newBatteryDescription = i18n(
              translations.newBatteryOptionYesDescriptionPremium,
              { brand: product.trackingDetails.brand },
            )
            modalName = MODAL_NAMES.PREMIUM_BATTERY
            prefix = 'FilledDiamond'
          } else if (isNewBatteryAvailable) {
            title = translations.titleStepBattery
            titleEmphasis = translations.titleEmphasisStepBattery
          } else {
            title = translations.titleStepBatteryDisabled
            titleEmphasis = translations.titleEmphasisStepBatteryDisabled
          }

          // Experiment to display battery capacity as labels
          if (ppBatteryCopyExperiment === 'withPercentages') {
            newBatteryNoLabel = translations.newBatteryOptionStandardPercentage
            newBatteryLabel = isPremiumGradeSelected
              ? translations.newBatteryOptionPremiumPercentage
              : translations.newBatteryOptionBatteryPercentage
          }

          return {
            id: 'battery',
            desktopIllustration: '/img/product/funnel/step-battery-square.svg',
            title,
            titleEmphasis,
            trackingId: 'new_battery',
            options: [
              {
                ...mapOption(
                  pickersBattery
                    ?.items[0] as GetPickersResponse['pickers'][number]['items'][number],
                ),
                label: newBatteryNoLabel,
                subLabel: translations.newBatteryOptionNoDescription,
                trackingValue: 'deactivate',
              },
              {
                ...mapOption(
                  pickersBattery
                    ?.items[1] as GetPickersResponse['pickers'][number]['items'][number],
                ),
                style: {
                  prefix,
                },
                label: newBatteryLabel,
                subLabel: newBatteryDescription,
                trackingValue: 'activate',
              },
            ],
            guidance: {
              label: guidanceLabel,
              icon: IconBattery,
              modalName,
            },
          }
        }
        case 'dual_sim':
          return {
            id: 'sim',
            title: translations.titleStepSim,
            titleEmphasis: translations.titleEmphasisStepSim,
            trackingId: pickersSim?.trackingId,
            options:
              pickersSim?.items
                .map((option) => mapOption(option))
                .map(mapSimSubLabel) || [],
            desktopIllustration: '/img/product/funnel/step-sim-square.svg',
            guidance: {
              label: translations.helpCardSim,
              icon: IconSim,
              modalName: MODAL_NAMES.SIM,
            },
          }
        case 'storage':
          return {
            id: 'storage',
            title: translations.titleStepStorage,
            titleEmphasis: translations.titleEmphasisStepStorage,
            trackingId: pickersStorage?.trackingId,
            options:
              pickersStorage?.items.map((option) => mapOption(option)) ?? [],
            desktopIllustration: '/img/product/funnel/step-storage-square.svg',
          }
        case 'color':
          return {
            id: 'color',
            title: translations.titleStepColor,
            titleEmphasis: translations.titleEmphasisStepColor,
            columns: 2,
            trackingId: pickersColor?.trackingId,
            options:
              pickersColor?.items.map((option) => mapOption(option, 'color')) ??
              [],
          }
        case 'mobile_plan':
          return getMobilePlanStep(
            i18n,
            ppBouyguesOffersFilter,
            pickersMobilePlan,
          )
        default:
          return null
      }
    })
    .filter(Boolean)

  if (product.isSwapEligible) {
    const isSwapInCart =
      swapStatus === SWAP_SUCCESS && Boolean(swapOffer?.hasOffer)

    const tradeInStep = {
      id: 'tradein',
      type: 'service',
      title: translations.titleStepTradeIn,
      titleEmphasis: translations.titleEmphasisStepTradeIn,
      trackingId: 'swap',
      desktopIllustration: '/img/product/funnel/step-trade-in-square.svg',
      guidance: {
        label: translations.tradeInGuidance,
        icon: IconSwap,
        modalName: MODAL_NAMES.TRADE_IN,
      },
      options: [
        {
          value: 'off',
          label: translations.tradeInOptionNo,
          trackingValue: 'no',
          available: true,
          acquirable: true,
          // No is selected is no trade in offer is accepted. This means that we change the value only
          // if the user goes through the whole flow and ACCEPTS the offer
          selected: swapStatus === SWAP_SUCCESS && !swapOffer?.hasOffer,
          // We need to have a link prop to have the right button styling, but trade in has absolutely
          // no impact on the URL, so we keep the current route as href.
          link: route,
          style: {
            prefix: 'circle' as PickerPrefixes,
          },
        },
        {
          value: 'on',
          label: translations.tradeInOptionYes,
          // subLabel and tag values changes if the user accepted the offer.
          subLabel: i18n(translations.tradeInOptionYesDescription, {
            price: swapOffer?.price,
          }),
          tag: isSwapInCart
            ? i18n(translations.tradeInTagAccepted)
            : i18n(translations.tradeInTag),
          price: isSwapInCart ? swapOffer?.offerPrice : undefined,
          trackingValue: 'yes',
          available: true,
          acquirable: true,
          selected: isSwapInCart,
          link: route,
          dataQaAttribute: 'swap-cta',
          style: {
            prefix: 'circle' as PickerPrefixes,
          },
        },
      ],
    }

    if (
      ppBouyguesPositionExperiment === 'withBouyguesBelowTradein' &&
      pickersMobilePlan
    ) {
      steps.splice(steps.length - 1, 0, tradeInStep)
    } else {
      steps.push(tradeInStep)
    }
  }

  // A/B test to remove battery step in the US (as it's the same phones as premium) and in EU when premium is selected
  if (
    ppHideBatteryStepExperiment === 'hideBatteryInUsAndPremium' &&
    (isPremiumGradeSelected || countryCode === MarketCountryCode.US)
  ) {
    return (steps as unknown as Step[]).filter(({ id }) => id !== 'battery')
  }

  return steps as unknown as Step[]
}
